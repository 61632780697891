<template>
    <div class="h-full">
        <modal :visible="modalError">
            <div class="text-left text-danger-dark font-bold text-lg">Ошибка!</div>
            <div class="text-sm mt-5">{{ modalErrorMessage }}</div>
            <button class="btn btn_blue w-full mt-5" @click="modalError = false">Понятно</button>
        </modal>
        <div
            class="drop-area text-xs mi:w-32  p-2 h-full rounded-2xl bg-light-base"
            :class="{ 'drag-over': isDraggingOver }"
            @dragenter.prevent="handleDragEnter"
            @dragover.prevent="handleDragOver"
            @dragleave.prevent="handleDragLeave"
            @drop.prevent="handleDrop"
            ref="dropArea"
            v-if="!isUploaded"
        >

            <icon :icon-name="uploadType === 'audio' ? 'uploadMusic' : 'uploadAny'" class="mb-2"></icon>

            Перетащите файл или
            <span @click="openFileInput" class="cursor-pointer underline text-xs text-base-color"
                  :class="{ 'svg-over': isDraggingOver }">загрузите</span>

            <input type="file" @change="handleFileInput" ref="fileInput" :accept="fileAccept" class="hidden">

        </div>
        <div v-else class="text-xs mi:w-32  p-2 h-full rounded-2xl">
            <div class="flex justify-between">
                <icon :icon-name="uploadType === 'audio' ? 'uploadMusic' : 'uploadAny'" class="mb-2"></icon>
                <icon icon-name="closeBlueRing" class="cursor-pointer mb-2" @click="deleteDocument"
                      v-if="(props.data?.status !== 'moderating' && !isModerating) && props.data?.status !== 'active'"></icon>
            </div>
            <a :href="uploadData.original_url" @click.prevent="handleFileClick(uploadData)"
               class="text-xs text-base-color">{{ uploadData?.file_name ?? '' }}</a>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue';
import {apiRequest} from "@/utils/helpers";
import {SPORTSMANS_FILES_UPLOAD} from "@/router/api";
import {defineProps} from 'vue';
import useEmitter from "@/utils/useEmitter";
import Icon from "@/views/components/helpers/icon.vue";
import Modal from "@/views/components/modal/modal.vue";

const emitter = useEmitter();
const props = defineProps({
    uploadType: String,
    data: {
        type: Object,
        default: false,
    },

});

const isDraggingOver = ref(false);
const fileInput = ref(null);
const dropArea = ref(null);
const fileAccept = ref('.doc,.docx');
const isUploaded = ref(false);
const uploadData = ref(null);
const modalError = ref(false);
const isModerating = ref(false);
const modalErrorMessage = ref("Ошибка загрузки. Перезагрузите страницу или попробуйте позднее.");

const handlePropsChange = () => {
    if (props.uploadType === 'audio') {
        fileAccept.value = '.mp3';
        if (props.data?.media_files?.audio?.file_name) {
            isUploaded.value = true;
            uploadData.value = props.data.media_files.audio;
        }
    }
    if (props.uploadType === 'elements') {
        if (props.data?.media_files?.element?.file_name) {
            isUploaded.value = true;
            uploadData.value = props.data.media_files.element;
        }
    }
    if (props.uploadType === 'permission') {
        if (props.data?.media_files?.permission?.file_name) {
            isUploaded.value = true;
            uploadData.value = props.data.media_files.permission;
        }
    }
};

onMounted(() => {
    handlePropsChange();
});

watch(() => props.uploadType, handlePropsChange);
watch(() => props.data, handlePropsChange, {deep: true});

const deleteDocument = async () => {

    await apiRequest(SPORTSMANS_FILES_UPLOAD + "/" + props.data.reg_uuid,
        {type: props.uploadType, reg_uuid: props.data.reg_uuid}, 'put')
        .catch(e => {
            console.error(e)
        })
        .then(async resp => {
            console.log("delete doc", resp, props.data.reg_uuid)
            if (resp && resp.success) {
                emitter.emit("fileSportsmanUpload", resp.sportsman);
                emitter.emit("fileDelete", props.data.reg_uuid);
                isUploaded.value = false;
            }
        })

}

const handleDragEnter = (event) => {
    let parentElement = document.querySelector('.drop-area')
    if (parentElement.contains(event.relatedTarget)) {
        isDraggingOver.value = true;
    }

    isDraggingOver.value = true;
};

const handleDragOver = (event) => {
    event.preventDefault();
};

const handleDragLeave = (event) => {
    if (dropArea.value && !dropArea.value.contains(event.relatedTarget)) {
        isDraggingOver.value = false;
    }
};

const openFileInput = () => {
    fileInput.value.click();
};

const handleFileInput = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
        handleFiles(files[0]);
    }
};

const handleDrop = (event) => {
    event.preventDefault();
    isDraggingOver.value = false;
    const files = event.dataTransfer.files;
    if (files.length > 0) {
        handleFiles(files[0]);
    }
};

const upload = async (file) => {
    let formData = new FormData();

    formData.append('type', props.uploadType);
    formData.append('reg_uuid', props.data.reg_uuid);
    formData.append('event_id', props.data.event_id);
    formData.append('file', file, file.name);

    await apiRequest(SPORTSMANS_FILES_UPLOAD, formData)
        .catch(e => {
            if (e.message && e.fails) {
                modalErrorMessage.value = e.message + " " + e.fails;
                modalError.value = true;
            }
        })
        .then(async resp => {
            if (resp && resp.success) {
                console.log(resp, 'UPLOAD')
                isUploaded.value = true;
                emitter.emit("fileSportsmanUpload", resp.result);
            }
        })
}

const handleFiles = (file) => {
    upload(file);
};
const handleFileClick = (file) => {
    const fileUrl = file.original_url;
    const fileExtension = fileUrl.split('.').pop().toLowerCase();

    if (fileExtension === 'mp3') {
        window.open(fileUrl, '_blank');
    } else {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = file.file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};

emitter.on('moderatingStatus', (uuid) => {
    if (props?.data?.reg_uuid === uuid) {
        isModerating.value = true;
    }
})

</script>

<style scoped>

.drop-area {
    border: 1px dashed var(--light-border);
}

.drag-over {
    border: 1px dashed var(--base-color);

}

.svg-over, .drag-over {
    color: var(--base-color);
    fill: var(--base-color);
}

</style>
