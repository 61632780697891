<template>
    <div
        class="drop-area w-full text-xs"
        :class="{ 'drag-over': isDraggingOver }"
        @dragenter.prevent="handleDragEnter"
        @dragover.prevent="handleDragOver"
        @dragleave.prevent="handleDragLeave"
        @drop.prevent="handleDrop"
    >
        <div class="mr-5">
            <svg width="36" height="30" viewBox="0 0 36 30" xmlns="http://www.w3.org/2000/svg"
                 style="fill: var(--icon-base)">
                <path class="svg" :class="{ 'svg-over': isDraggingOver }"
                      d="M32.7273 0H2.72727C2.00395 0 1.31026 0.287337 0.7988 0.7988C0.287337 1.31026 0 2.00395 0 2.72727V27.2727C0 27.996 0.287337 28.6897 0.7988 29.2012C1.31026 29.7127 2.00395 30 2.72727 30H32.7273C33.4506 30 34.1443 29.7127 34.6557 29.2012C35.1672 28.6897 35.4545 27.996 35.4545 27.2727V2.72727C35.4545 2.00395 35.1672 1.31026 34.6557 0.7988C34.1443 0.287337 33.4506 0 32.7273 0ZM32.7273 2.72727V20.2415L28.2835 15.7994C28.0303 15.5461 27.7296 15.3452 27.3987 15.2081C27.0677 15.071 26.713 15.0004 26.3548 15.0004C25.9966 15.0004 25.6419 15.071 25.311 15.2081C24.9801 15.3452 24.6794 15.5461 24.4261 15.7994L21.017 19.2085L13.517 11.7085C13.0056 11.1974 12.3122 10.9104 11.5892 10.9104C10.8662 10.9104 10.1728 11.1974 9.66136 11.7085L2.72727 18.6426V2.72727H32.7273ZM2.72727 22.5L11.5909 13.6364L25.2273 27.2727H2.72727V22.5ZM32.7273 27.2727H29.0847L22.9483 21.1364L26.3574 17.7273L32.7273 24.0989V27.2727ZM20.4545 10.2273C20.4545 9.82272 20.5745 9.42725 20.7993 9.09088C21.024 8.75451 21.3435 8.49233 21.7172 8.33752C22.091 8.1827 22.5023 8.1422 22.899 8.22112C23.2958 8.30005 23.6603 8.49486 23.9464 8.78092C24.2324 9.06698 24.4272 9.43145 24.5061 9.82822C24.5851 10.225 24.5446 10.6363 24.3898 11.01C24.2349 11.3838 23.9728 11.7032 23.6364 11.928C23.3 12.1528 22.9046 12.2727 22.5 12.2727C21.9575 12.2727 21.4372 12.0572 21.0536 11.6736C20.67 11.29 20.4545 10.7698 20.4545 10.2273Z"/>
            </svg>
        </div>

        Перетащите изображение сюда или <span @click="openFileInput" class="cursor-pointer underline ml-2 text-xs"
                                              :class="{ 'svg-over': isDraggingOver }">загрузите файл</span>

        <input type="file" @change="handleFileInput" style="display: none" ref="fileInput" :accept="acceptDoc">

    </div>
    <alerts :alertType="uploadType" />
</template>

<script setup>
import {ref} from 'vue';
import {apiRequest, personalDataUpdate} from "@/utils/helpers";
import {FILES} from "@/router/api";
import { defineProps } from 'vue';
import Alerts from "@/views/components/helpers/alerts.vue";
import useEmitter from "@/utils/useEmitter";
import {useRoute} from "vue-router";

const route = useRoute();
const emitter = useEmitter();
const props = defineProps({
    uploadType: String,
    message: {
        type: String,
        default: 'Не удалось загрузить файл'
    },
    returnUrl: {
        type: Boolean,
        default: false,
    },
    acceptDoc: {
        type: String,
        default: 'image/*'
    },
    eventId: {
        // type: String,
        type: [Number, String],
        default: 1
    },
    fileUploadNumber: {
        type: Number,
        default: 1,
    },
    newUser: {
        type: Boolean,
        default: false,
    }
});

const isDraggingOver = ref(false);
const fileInput = ref(null);

const handleDragEnter = () => {
    isDraggingOver.value = true;
};

const handleDragOver = (event) => {
    event.preventDefault();
};

const handleDragLeave = () => {
    isDraggingOver.value = false;
};

const openFileInput = () => {
    fileInput.value.click();
};

const handleFileInput = (event) => {
    const files = event.target.files;
    // if (files.length > 0) {
        handleFiles(files);
        fileInput.value.value = '';
    // }
};

const handleDrop = (event) => {
    event.preventDefault();
    isDraggingOver.value = false;
    const files = event.dataTransfer.files;

    // if (files.length > 0) {
        handleFiles(files);
        event.dataTransfer.value = '';
    // }
};

const upload = async (files) => {
    let formData = new FormData();

    formData.append('type', props.uploadType);

    Array.from(files).forEach((file) => {
        formData.append(`image[]`, file, file.name);
    });

    formData.append('returnUrl',    props.returnUrl);
    formData.append('eventId',      props.eventId);
    formData.append('newUser',      props.newUser);
    formData.append('userId',       route?.params?.id ?? '');

    await apiRequest(FILES, formData)
        .catch(e => {
            emitter.emit("alert", {alertType: props.uploadType, message: props.message});
        })
        .then(async resp => {
            if (resp && resp.success) {
                if(!props.returnUrl) {
                    await personalDataUpdate();
                } else {
                    emitter.emit("returnUrl", {uploadType: props.uploadType, url: resp.url, success: true});
                }
            }
        })
}

const verifyFileType = (file) => {
    const [mainType, subType] = file.type.split('/');
    const [acceptedMainType, acceptedSubType] = props.acceptDoc.split('/');

    if (mainType !== acceptedMainType || (acceptedSubType !== '*' && subType !== acceptedSubType)) {
        emitter.emit("alert", { alertType: props.uploadType, message: props.message});
        return false;
    }
    return true;
}

const handleFiles = (files) => {
    let validate = false;
    if(files.length > 1) {
        for(let i = 0; i < files.length; i++) {
            if(!verifyFileType(files[i])) return;
        }
        validate = true;
    } else {
        validate = verifyFileType(files[0]);
    }
    if(!validate) return ;

    upload(files);
};


</script>

<style scoped>
span {
    color: var(--base-color);
}

.drop-area {
    border-radius: 15px;
    border: 1px dashed var(--light-border);
    background: var(--light-base);
    display: flex;
    height: 90px;
    padding: 0 20px;
    justify-content: left;
    align-items: center;
    align-self: stretch;
}

.drag-over {
    border: 1px dashed var(--base-color);

}

.svg-over, .drag-over {
    color: var(--base-color);
    fill: var(--base-color);
}

</style>
