import PageNotFound from '../views/pages/404/index.vue';
import RegIndex from '../views/pages/registration/index.vue';
import RegType from '../views/pages/registration/registration.vue';

import EChampRegistration from "../views/pages/registration/eChampRegistration.vue";

import Home from '../views/pages/home';
import Dashboard from '../views/pages/dashboard/index';
import Login from '../views/pages/auth/login.vue';
import PasswordRecovery from '../views/pages/auth/passwordRecovery.vue';
import PasswordReset from '../views/pages/auth/passwordReset.vue';
import {createRouter, createWebHistory} from "vue-router";
import authGuard from "@/router/middleware/auth";
import Profile from "@/views/pages/dashboard/profile/profile.vue";
import Sportsmans from "@/views/pages/dashboard/sportsmans/sportsmans.vue"
import AddNewSportsman from "@/views/pages/dashboard/sportsmans/addNewSportsman.vue"
import editSportsman from "@/views/pages/dashboard/sportsmans/editSportsman.vue"
import Verification from "@/views/pages/dashboard/verification/verification.vue";
import autRoles from "@/router/middleware/aut";
import MyCompetitions from "@/views/pages/dashboard/competitions/myCompetitions.vue";
import Payments from "@/views/pages/dashboard/payment/payments.vue";
import PaymentEvent from "@/views/pages/dashboard/payment/paymentEvent.vue";
import SuccessPayment from "@/views/pages/dashboard/payment/successPayment.vue";
import CompetitionsFileUpload from "@/views/pages/dashboard/competitions/competitionsFileUpload.vue";
import CreateCompetition from "@/views/pages/dashboard/createCompetition/createCompetition.vue";
import CompetitionLayout from '@/views/pages/dashboard/competitions/competitionLayout.vue';
import Calendar from "@/views/pages/dashboard/calendar/calendar.vue";
import Registration from "@/views/pages/dashboard/registration/registration.vue";

const routesMain = [
    {
        path: "/registration",
        children: [
            {
                path: "",
                component: RegIndex,
                name: 'registration',
            },
            {
                path: "e-champ-trainer",
                component: EChampRegistration,
                name: 'registration.type.eChampTrainer',
            },
            {
                path: "e-champ-sportsman",
                component: EChampRegistration,
                name: 'registration.type.eChampSportsman',
            },
            {
                path: ":regType",
                component: RegType,
                name: 'registration.type',
            },
        ]
    },
    {
        path: "/login",
        component: Login,
        name: 'login',
    },
    {
        path: "/password-recovery",
        component: PasswordRecovery,
        name: 'passwordRecovery',
    },
    {
        path: "/password-reset/:id",
        component: PasswordReset,
        name: 'passwordReset',
    },
    {
        path: "/",
        component: Home,
        name: 'home',
    },
    {
        path: "/success",
        component: SuccessPayment,
        name: 'SuccessPayment',
        meta: {isAuth: true},
    },
    {
        path: "/dashboard",
        meta: {isAuth: true},
        children: [
            {
                path: "",
                component: Dashboard,
                name: 'dashboard',
                meta: {isAuth: true},
            },
            {
                path: "profile",
                component: Profile,
                name: 'profile',
                meta: {isAuth: true},
            },
            {
                path: "my-calendar",
                component: Calendar,
                name: 'calendar',
                meta: {isAuth: true},
            },
            {
                path: "registration/:id",
                component: Registration,
                name: 'event-registration',
                meta: {isAuth: true},
            },
            {
                path: "sportsmans",
                meta: {isAuth: true},
                children: [
                    {
                        path: "",
                        component: Sportsmans,
                        name: 'sportsmans',
                        meta: {isAuth: true},
                    },
                    {
                        path: "new/:id",
                        component: AddNewSportsman,
                        name: 'addNewSportsman',
                        meta: {isAuth: true},
                    },
                    {
                        path: "edit/:id",
                        component: editSportsman,
                        name: 'editSportsman',
                        meta: {isAuth: true},
                        props: true,
                    }
                ]
            },
            {
                path: "verification",
                component: Verification,
                name: 'verification',
                meta: {isAuth: true, roles: ['super']},
            },
            {
                path: "my-competitions",
                meta: {isAuth: true},
                children: [
                    {
                        path: "",
                        component: MyCompetitions,
                        name: 'my-competitions',
                        meta: {isAuth: true},
                    },
                    {
                        path: ":id",
                        component: CompetitionLayout,
                        name: 'show-competition',
                        meta: {isAuth: true},
                    },
                    {
                        path: "file-upload/:id",
                        component: CompetitionsFileUpload,
                        name: 'file-upload',
                        meta: {isAuth: true},
                    },
                ]
            },
            {
                path: "payment",
                meta: {isAuth: true},
                children: [
                    {
                        path: "",
                        component: Payments,
                        name: 'payment',
                        meta: {isAuth: true},
                    },
                    {
                        path: ":id",
                        component: PaymentEvent,
                        name: 'payment-event',
                        meta: {isAuth: true},
                    },
                ]
            },
            {
                path: "create-competition/:id",
                meta: {isAuth: true},
                children: [
                    {
                        path: "",
                        component: CreateCompetition,
                        name: 'create-competition',
                        meta: {isAuth: true},
                    },
                ]
            },

        ]
    },

    {
        path: "/:catchAll(.*)",
        component: PageNotFound,
        name: '404',
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes: routesMain,
});

// проверка авторизации
router.beforeEach(authGuard);
router.beforeEach(autRoles);

export default router;