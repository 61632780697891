import store from "@/store";
import {navigateTo} from "@/utils/helpers";


export default function autRoles(to, from, next) {
    if(to.meta.roles) {
        if(store.state.roles.some(role => to.meta.roles.includes(role.name))) {
            next();
        } else {
            navigateTo('dashboard');
        }
    } else {
        next();
    }
}