<template>
    <dashboardLayout>
        <navigation-container class="mt-10">
            <wrap-section :is-empty="true">
                <div class="sm:flex">
                    <div class="flex-1 flex items-center">
                        <h2 class="text-4xl">Мои соревнования</h2>
                    </div>


                    <button class="mt-5 w-full btn btn_blue sm:w-80 sm:mt-0 d_center pr-5 pl-3 "
                            @click="createCompetition" v-if="store.state.permissions.includes('public_create_event')">
                        <icon icon-name="white_plus" class="mr-3"></icon>
                        Создать соревнование
                    </button>
                </div>

                <Tabs>
                    <Tab active="true" title="Предстоящие" :total="upcomingEvents.pagination?.total">
                        <wrap-section :is-empty="true">
                            <competitionCard v-for="el in upcomingEvents?.data" :data="el" class="mt-5"/>

                            <pagination :meta="upcomingEvents?.pagination" v-model="upcomingEventsCurrentPage"
                                        class="mt-7"/>
                        </wrap-section>

                    </Tab>
                    <Tab title="Архив" :total="pastEvents.pagination?.total">
                        <wrap-section :is-empty="true">
                            <competitionCard v-for="el in pastEvents.data" :data="el" :past-event="true"
                                             class="mt-5"></competitionCard>

                            <pagination :meta="pastEvents?.pagination" v-model="pastEventsCurrentPage" class="mt-7"/>
                        </wrap-section>
                    </Tab>
                </Tabs>
            </wrap-section>
        </navigation-container>
    </dashboardLayout>
</template>

<script setup>

import dashboardLayout from "@/views/layout/dashboard";
import Tabs from "@/views/components/helpers/Tabs.vue";
import Tab from "@/views/components/helpers/Tab.vue";
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import {apiRequest, navigateTo, uuid} from "@/utils/helpers";
import {EVENTS} from "@/router/api";
import {onMounted, ref, watch} from "vue";
import competitionCard from "@/views/components/competitions/competitionsCard.vue";
import {useRoute} from 'vue-router';
import Icon from "@/views/components/helpers/icon.vue";
import router from "@/router/router";
import Pagination from "@/views/components/helpers/pagination.vue";
import store from "@/store";
import {getUpcomingEvents} from "@/utils/helpers";
import useEmitter from "@/utils/useEmitter";

const emitter = useEmitter();
const route = useRoute();
const eventId = ref(false);
const upcomingEvents = ref({});
const upcomingEventsCurrentPage = ref(1);
const pastEvents = ref({});
const pastEventsCurrentPage = ref(1);

const getPastEvents = () => {
    apiRequest(EVENTS + "?event_time=past&self=true&page=" + pastEventsCurrentPage.value, null, 'get')
        .catch(e => {
            console.log('error', e)
        })
        .then(resp => {
            if (resp && resp.success) {
                pastEvents.value.data = resp.result.data;
                pastEvents.value.pagination = resp.result.pagination;
            }
        })
}

onMounted(() => {
    if (route.query.event_id) {
        eventId.value = route.query.event_id;
        return;
    }
    upcomingEvents.value.data = store.state.upcomingEvents;
    getUpcomingEvents(1);
    getPastEvents();

});

watch(upcomingEventsCurrentPage, (newPage) => {
    getUpcomingEvents(upcomingEventsCurrentPage.value);
});
watch(pastEventsCurrentPage, (newPage) => {
    getPastEvents();
});

emitter.on('upcomingEvents', value => {
    if(value.data) {
        upcomingEvents.value.data = value.data;
        upcomingEvents.value.pagination = value.pagination;
    }
})

const createCompetition = () => {
    router.push({name: 'create-competition', params: {id: uuid()}})
}
const loadComp = () => {

}

defineExpose({
    loadComp,
});


</script>
