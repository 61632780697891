<template>

    <div class="divide-y divide-light-border divide-solid mt-7">
        <div v-if="data.length">
            <div v-for="el in data"  :key="el.uuid" >
                <represent-add-item :data="el.user" :representId="el.id" />
            </div>

            <pagination :meta="meta" v-model="currentPage" class="mt-5"/>

            <alerts alertType="representDescription"/>
            <button class="btn mt-8 btn_blue w-full sm:w-64 " @click="saveRepresentDescription" v-if="data.length">
                Сохранить
            </button>
        </div>

        <div v-else>
            Для данного соревнования представители еще не добавлены
        </div>
    </div>

</template>

<script setup>
import {apiRequest} from "@/utils/helpers";
import {defineProps, onMounted, ref, watch} from "vue";
import Pagination from "@/views/components/helpers/pagination.vue";
import {REPRESENT} from "@/router/api";
import useEmitter from "@/utils/useEmitter";
import RepresentAddItem from "@/views/components/createCompetition/representAddItem.vue";
import {useRoute} from "vue-router";
import Alerts from "@/views/components/helpers/alerts.vue";

const meta = ref({});
const input = ref("");
const data = ref([]);
const currentPage = ref(1);
const emit = defineEmits(['update:modelValue']);
const emitter = useEmitter();
const route = useRoute();
const props = defineProps({
    modelValue: {
        type: Number,
        required: true,
    },
});

const loadRepresent = async (page) => {

    try {
        const response = await apiRequest(REPRESENT, { params: { page: page, event: route.params.id } }, 'get');
        if (response && response.success && response.result) {
            data.value = response.result.data;
            meta.value = response.result.pagination;
            emit('update:modelValue', meta.value.total);
        }
    } catch (e) {
        console.error('Ошибка при загрузке данных о спортсменах:', e);
    }
};
const saveRepresentDescription = () => {
    emitter.emit('represent_update_job')
    emitter.emit("success", {alertType: 'representDescription', message: "Данные представителей успешно обновлены"});
}

emitter.on('represent_update', () => {
    loadRepresent(currentPage.value)
})

watch(currentPage, (newPage) => {
    loadRepresent(newPage);
});

onMounted(() => {
    loadRepresent(currentPage.value);
});

defineExpose({
    loadRepresent,
});
</script>