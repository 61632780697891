<template>
    <modal :visible="showErrorModal">
        <div class="text-left text-danger-dark font-bold text-lg">Ошибка!</div>
        <div v-for="el in props.data?.comments" class="mt-3">
            <div class="text-left">{{el.created_at ? moment(el.created_at).format('DD.MM.yy') : null}}</div>
            <div class="text-left font-bold">{{el.message}}</div>

        </div>
        <button class="btn btn_blue w-full mt-5" @click="showErrorModal = false">Понятно</button>
    </modal>
    <div v-if="status === 'active'" class="w-full bg-green-message rounded-lg border">
        <div class="text-center text-black text-xs font-medium leading-9 d_center">
            Одобрено
            <icon icon-name="greenMark" class="ml-3"></icon>
        </div>
    </div>
    <div v-if="status === 'rejected'" class="w-full bg-danger-middle rounded-lg border cursor-pointer"
         @click="showErrorModal = true">
        <div class="text-center text-white text-xs font-medium leading-9 d_center">
            Ошибка
            <icon icon-name="attentionWhite" class="ml-3"></icon>
        </div>
    </div>
    <button v-if="status === 'draft' && getUploadData()" class="w-full btn_blue rounded-lg"
            @click="sendOnModeration(props.data.reg_uuid)">
        <span class="text-xs text-white leading-9">
            Отправить
        </span>
    </button>
    <div v-if="status === 'moderating'" class="w-full bg-light-border rounded-lg border">
        <div class="text-center text-black text-xs font-medium leading-9 d_center">
            На проверке
        </div>
    </div>
    <div v-if="status === 'base'"></div>
</template>

<script setup>
import {defineProps, ref, watch} from 'vue';
import Icon from '@/views/components/helpers/icon.vue';
import useEmitter from "@/utils/useEmitter";
import {apiRequest} from "@/utils/helpers";
import {EVENT_UNIT} from "@/router/api";
import Modal from "@/views/components/modal/modal.vue";
import moment from 'moment';

const showErrorModal = ref(false);
const status = ref('draft');
const props = defineProps({
    data: Object,
});
const emitter = useEmitter();
const sendOnModeration = async (uuid) => {
    await apiRequest(EVENT_UNIT + "/" + uuid + '/send-to-moderation', [], 'post')
        .catch(e => {
            console.error(e)
        })
        .then(async resp => {
            if (resp && resp.success) {
                status.value = 'moderating';
                emitter.emit("moderatingStatus", props.data.reg_uuid);
            }
        })
}

emitter.on('closeModal', () => {
    showErrorModal.value = false;
})
emitter.on('fileDelete', (uuid) => {
    if(props?.data?.reg_uuid === uuid) {
        status.value = 'base';
    }
    showErrorModal.value = false;
})

const getUploadData = () => {
    return !!(props.data?.media_files?.audio?.original_url
        && props.data?.media_files?.element?.original_url
        && props.data?.media_files?.permission?.original_url);

}

watch(() => props.data, (newData) => {
    if (newData?.status && newData.status !== status.value) {
        status.value = newData.status;
    }
}, { immediate: true, deep: true });

status.value = props.data?.status || 'draft';

</script>
