<template>
    <div class="w-full sidebar-line"></div>
</template>

<script>

</script>
<style scoped>
.sidebar-line {
    border: 1px solid var(--dark-base);
}
</style>
