<template>
    <div class="d_center" v-if="store.state.permissions.includes('public_create_event')">
        <div class="hidden sm:block">
            <span v-if="isOn" class="text-grey-text">Опубликовано</span>
            <span class="text-grey-text" v-else>Опубликовать</span>
        </div>
        <div :class="{'bg-icon-base': !isOn, 'bg-green-message-text': isOn}" @click="toggleSwitch"
             class="relative inline-flex items-center h-6 rounded-full w-11 cursor-pointer ml-5">
            <span :class="{'translate-x-6': isOn, 'translate-x-1': !isOn}"
                  class="inline-block w-4 h-4 transform bg-white rounded-full transition-transform "></span>
        </div>
    </div>
</template>

<script setup>
import {defineProps, onMounted, ref, toRefs, watch} from 'vue'
import {apiRequest} from "@/utils/helpers";
import {EVENTS} from "@/router/api";
import store from "@/store";

const props = defineProps({
    data: Object,
    default: {}
});
const isOn = ref(false)

const toggleSwitch = () => {
    isOn.value = !isOn.value

    apiRequest(EVENTS + '/' + props.data.uuid, {type: 'toggleActive', is_active: isOn.value}, 'put')
        .catch(e => {
            console.log('error', e)
        })
}

onMounted(() => {
    isOn.value = props.data?.is_active;
});

watch(() => props.data, (newValue) => {
    isOn.value = newValue.is_active;
}, { deep: true });



</script>