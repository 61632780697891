<template>
    <div :class="{ 'p-10': !isMinHeight, 'p-7': isMinHeight }" class="section mt-5 shadow-base bg-white rounded-3xl" v-if="!isEmpty">
        <slot />
    </div>
    <div :class="{ 'p-10': !isMinHeight, 'p-7': isMinHeight }" class="section mt-5 !p-0" v-else>
        <slot />
    </div>
</template>

<style scoped>
    .section {
        width: 760px;
    }

    @media (max-width: 1164px) {
        .section {
            width: 100%;
        }
    }
    @media (max-width: 768px) {
        .section {
            padding: 30px;
        }
    }
    @media (max-width: 640px) {
        .section {
            padding: 20px;
        }
    }
    @media (max-width: 350px) {
        .section {
            padding: 20px 12px;
        }
    }
</style>
<script setup>
import store from "@/store";
import {ref} from "vue";

const props = defineProps({
    isEmpty: {
        type: Boolean,
        default: false,
    },
})

const isMinHeight = ref(store.state.isMinHeight);

</script>