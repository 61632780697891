<template>
    <wrap-section class="!p-0 border-solid border border-dark-border rounded-2xl overflow-hidden">
        <div v-for="(el, i) in registrations" :class="{'bg-base-bg': i % 2 !== 0}">
            <div class="border border-solid border-grey-text" v-if="i"></div>

            <div class="p-2 text-xs">
                <div class="flex justify-between">
                    <div>
                        <div class="text-base-color text-sm">ФИО</div>
                        <div v-for="user in el.sportsmen">
                            {{ user.full_name }}
                            {{ console.log('el', registrations) }}
                        </div>
                    </div>
                    <div class="d_center">
                        <button class="bg-danger-light rounded-xl" @click="deleteRegistration(el.uuid)">
                            <icon icon-name="delete"/>
                        </button>
                    </div>
                </div>
                <div class="border border-solid border-light-border mt-3 mb-3"></div>
                <div class="flex justify-between gap-2">
                    <div v-if="el.equipment" class="flex-1">
                        <div class="text-base-color text-sm">Снаряд</div>
                        {{el.equipment.title}}
                    </div>
                    <div class="flex-1">
                        <div class="text-base-color text-sm">Категория</div>
                        <!-- {{el.direction_category.title}} -->
                    </div>
                    <div v-if="el.level" class="flex-1">
                        <div class="text-base-color text-sm">Уровень</div>
                        {{el.level.title}}
                    </div>
                </div>
            </div>


        </div>

    </wrap-section>



</template>

<script setup>
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import Icon from "@/views/components/helpers/icon.vue";

const emit = defineEmits(['delete-registration']);
const deleteRegistration = (uuid) => {
    emit('delete-registration', uuid);
};
const props = defineProps({
    registrations: {
        type: Array,
        default: [],
    },
})




</script>
