<template>
    <div class="w-full" v-for="el in faqCounter">
        <div class="w-full h-px bg-dark-border mt-4 mb-3" v-if="el !== 1"></div>

        <input-custom label="Вопрос" type="text" v-model="faqs[el - 1].question" class="mt-4 mob_margin"
                      labelClass="pass-update-org"/>
        <text-area-custom label="Ответ" type="text" v-model="faqs[el - 1].answer" class="mt-4 mob_margin"
                          labelClass="pass-update" areaHeight="h-17"/>
    </div>

    <alerts alertType="faqItem"/>

    <div class="w-full flex mt-3 text-base-color cursor-pointer justify-end"
         @click="addExtraFaq">
        <div class="d_center">
            <icon icon-name="plus" class="mr-3"/>
        </div>
        Добавить ещё вопрос
    </div>

    <button class="btn mt-8 btn_blue w-full sm:w-64 " @click="saveFaq">
        Сохранить
    </button>
</template>

<script setup>
import InputCustom from "@/views/components/inputComponent/inputCustom.vue";
import {ref, watch} from "vue";
import useEmitter from "@/utils/useEmitter";
import Icon from "@/views/components/helpers/icon.vue";
import Alerts from "@/views/components/helpers/alerts.vue";
import TextAreaCustom from "@/views/components/inputComponent/textAreaCustom.vue";
import {apiRequest} from "@/utils/helpers";
import {EVENT_FAQ} from "@/router/api";
import {useRoute} from "vue-router";

const route = useRoute()
const faqCounter = ref(1);
const emitter = useEmitter();
const faqs = ref([{question: '', answer: ''}]);
const props = defineProps({
    data: {
        type: Object,
        default: {},
    },
})

const saveFaq = async () => {

    if (!faqs.value[0].answer && !faqs.value[0].question) {
        return emitter.emit("alert", {
            alertType: 'faqItem',
            message: "Для сохранения заполните поля Вопрос/Ответ",
            isSuccess: 'false'
        });
    }

    try {
        const response = await apiRequest(EVENT_FAQ, {data: faqs.value, event_id: route.params.id}, 'post');
        if (response && response.success && response.message) {
            return emitter.emit("success", {
                alertType: 'faqItem',
                message: response.message,
            });
        }
    } catch (e) {
        console.error('Ошибка при загрузке данных о спортсменах:', e);
    }


}
const addExtraFaq = () => {
    if (faqs.value[faqCounter.value - 1].answer && faqs.value[faqCounter.value - 1].question) {
        faqs.value.push({question: '', answer: ''})
        faqCounter.value++;
    } else {
        emitter.emit("alert", {
            alertType: 'faqItem',
            message: "Для добавления следущего ответа, заполните текущее поле",
            isSuccess: 'false'
        });
    }
}

watch(() => props.data, (newValue) => {
    if (newValue  && typeof newValue === 'object' && Object.keys(newValue).length > 0 && newValue.faq.length > 0) {
        faqs.value = newValue.faq
        faqCounter.value = newValue.faq.length;
    }

}, { deep: true, immediate: true });

</script>