<template>
    <wrap-section class="!p-0 border-solid border border-dark-border rounded-2xl overflow-hidden">
        <table class="min-w-full">
            <thead class="bg-light-base">
            <tr class="py-3">
                <th class="w-48 p-4 text-left text-xs text-dark-base">
                    ФИО
                </th>
                <th class="w-36 p-2 text-left text-xs text-dark-base">
                    Снаряд
                </th>
                <th class="w-36 p-2 text-left text-xs text-dark-base">
                    Категория
                </th>
                <th class="w-36 p-2 text-left text-xs text-dark-base">
                    Уровень
                </th>
                <th class="w-8 p-4 text-left text-xs text-dark-base">

                </th>
            </tr>
            </thead>
            <tbody class="bg-white">
            <tr class="py-4 border-t border-solid border-light-border" v-for="el in registrations" v-if="registrations && registrations.length">
                <td class="text-left align-middle p-4 text-sm ">
                    <div class="w-full h-full" v-for="user in el.sportsmen">
                        <b>{{ user.full_name }}</b><br>
                    </div>
                </td>
                <td v-if="el.equipment" class="align-middle p-2 gap-1 h-full">
                    {{el.equipment.title}}
                </td>
                <td v-if="el.direction_category" class="align-middle p-2 gap-1 h-full">
                    {{el.direction_category.title}}
                </td>
                <td v-if="el.level" class="align-middle p-2 gap-1 h-full">
                    {{el.level.title}}
                </td>
                <td class="text-center align-middle p-2">
                    <button class="bg-danger-light rounded-xl" @click="deleteRegistration(el.uuid)">
                        <icon icon-name="delete"/>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>



    </wrap-section>



</template>

<script setup>
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import Icon from "@/views/components/helpers/icon.vue";
const emit = defineEmits(['delete-registration']);
const deleteRegistration = (uuid) => {
    emit('delete-registration', uuid);
};
const props = defineProps({
    registrations: {
        type: Array,
        default: [],
    },
})

</script>
