<template>
    <div class="w-full">
        <div class="w-full bg-green-message rounded-lg border">
            <div class="text-center text-black text-xs font-medium leading-9 d_center">
                Оплачено
                <icon icon-name="greenMark" class="ml-3"></icon>
            </div>
        </div>
<!--        <button class="btn btn_blue w-full !text-sm p-2 d_center rounded"-->
<!--                @click="openModal = true">-->
<!--            Оплатить-->
<!--        </button>-->
    </div>

<!--    <Modal :visible="openModal">-->
<!--        <div class="text-left text-base-color font-bold text-lg">Аккаунт верифицирован</div>-->
<!--        <div class="text-sm mt-5">Ваш профиль пользователя подтвержден.</div>-->
<!--        <button class="btn btn_blue w-full mt-5" @click="">Понятно</button>-->
<!--    </Modal>-->
</template>

<script setup>
import Modal from "@/views/components/modal/modal.vue";
import {ref} from "vue";
import Icon from "@/views/components/helpers/icon.vue";

const openModal = ref(false);

</script>

<style scoped>

</style>