<template>
    <baseLayout>
        <div class="wrap_login d_center">
            <div class="login_form">
                <h1>{{ is_mobile ? "Регистрация" : "Регистрация " + reg_type }}</h1>

                <input-custom label-class="min-w-24" label="Имя*" type="text" v-model="formData.name" class="mt-5 mob_margin"/>
                <input-custom label-class="min-w-24" label="Отчество*" type="text" v-model="formData.middle" class="mt-4 mob_margin"/>
                <div class="mt-1 sm:ml-24 flex">
                    <div class="d_center">
                        <input type="checkbox" id="hasMiddle" name="scales" checked class="cursor-pointer" v-model="hasNoMiddle"/>
                    </div>
                    <label for="hasMiddle" class="ml-3 cursor-pointer text-sm">У меня нет отчества</label>
                </div>
                <input-custom label-class="min-w-24" label="Фамилия*" type="text" v-model="formData.surname" class="mt-4 mob_margin"/>

                <div class="wrap_age_gender mt-4">
                    <date-picker v-model="formData.birthday" label="Дата <br> рождения*" label-class="min-w-24"/>
                    <div class="mt-4 gender sm:mt-0 d_center">
                        <gender v-model="formData.sex"/>
                    </div>
                </div>

                <input-custom label-class="min-w-24" label="Email*" type="text" v-model="formData.email" class="mt-4 mob_margin"/>

                <alerts alertType="userReg" />

                <button class="btn mt-6 btn_blue w-full" @click="registration">
                    {{ is_mobile ? "Зарегистрироваться" : "Зарегистрировать новый аккаунт" }}
                </button>
                <div class="have_account">
                    Уже зарегистрированы?
                    <router-link :to="{ name: 'login' }">Войти</router-link>
                </div>
            </div>
        </div>
    </baseLayout>
</template>

<script setup>
import baseLayout from "@/views/layout/base";
import {useRoute} from 'vue-router'
import Alerts from "@/views/components/helpers/alerts.vue";
import InputCustom from "@/views/components/inputComponent/inputCustom.vue";
import DatePicker from "@/views/components/inputComponent/datePicker.vue";
import {ref} from "vue";
import Gender from "@/views/components/inputComponent/gender.vue";
import useEmitter from "@/utils/useEmitter";
import {apiRequest, navigateTo} from "@/utils/helpers";
import {REGISTER} from "@/router/api";



const emitter = useEmitter();
const route = useRoute();
const hasNoMiddle = ref(false);

const formData = ref({
    name: '',
    sex: '',
    surname: '',
    middle: '',
    birthday: '',
    email: '',
    role: route.params.regType,
});

let reg_type = '';

const is_mobile = window.innerWidth < 576;

const validation = () => {
    let base = 'Проверьте корректность ввода поля '
    if(formData.value.name < 3) return emitter.emit("alert", base + "имя");
    if(!hasNoMiddle.value) {
        if(formData.value.middle < 3) return emitter.emit("alert", base + "отчество");
    }
    if(formData.value.surname < 3) return emitter.emit("alert", base + "фамилия");
    if(formData.value.birthday < 10) return emitter.emit("alert", base + "дата рождения");
    if(formData.value.sex < 4) return emitter.emit("alert", base + "пол");
    if(formData.value.email < 4) return emitter.emit("alert", base + "email");

    return true;
}

emitter.on('birthday', value => {
    formData.value.birthday = value;
})

const registration = async () => {
    if (validation()) {
        await apiRequest(REGISTER, formData.value)
            .then(resp => {
                if(resp && resp.success) {
                    formData.value.name = '';
                    formData.value.surname = '';
                    formData.value.middle = '';
                    formData.value.birthday = '';
                    formData.value.sex = '';
                    formData.value.email = '';
                    return emitter.emit("success", {alertType: 'userReg', message: resp.message});
                }
            })
            .catch(e => {
                if(e && e.message) {
                    return emitter.emit("alert", e.message);
                }
                console.log('error reject', e)
            })
    }
}


if (route.params.regType === 'trainer') {
    reg_type = ' тренера';
} else if (route.params.regType === 'sportsman') {
    reg_type = ' спортсмена';
} else if (route.params.regType === 'parent') {
    reg_type = ' родителя';
}

</script>

<style>
input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 1em;
    height: 1em;
    border: 1px solid var(--light-border);
    border-radius: 3px;
    position: relative;
    cursor: pointer;
}

input[type="checkbox"]:checked {
    border-color: var(--base-color);
    background-color: var(--base-color);
}

input[type="checkbox"]:checked::after {
    content: '✓';
    color: white;
    font-size: 0.75em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.wrap_age_gender {
    display: flex;
    justify-content: space-between;
}

.have_account {
    margin-top: 20px;
    text-align: center;
    color: var(--base-color);
}

.have_account a {
    color: var(--base-color);
}

@media (max-width: 639px) {
    .wrap_age_gender {
        display: block;
    }
}

@media (max-width: 576px) {
    .gender {
        margin-left: unset;
    }
}

</style>
