<template>
    <div v-if="!personal_doc_url">
        <div class="text-base font-bold">Загрузите документ подтверждения личности Спортсмена*</div>
        <div class="text-base mb-2">Паспорт или свидетельство о рождении в форматах JPG, PNG</div>
        <file-upload uploadType="personalDoc" :userUuid="route.params.id"
                     message="Ошибка при загрузке Документа удостоверяющего личность"
                     :return-url="true"
        />
        <div class="text-xs mt-2">*Документ после проверки будет удален с платформы</div>
    </div>

    <div class="flex justify-between" v-else>
        <div>
            Вы загрузили документ <a :href="personal_doc_url" target="_blank" class="text-base-color">удостоверящий
            личность спортсмена</a>
        </div>
        <div class="cursor-pointer" @click="deletePersonalDoc">
            <icon icon-name="delete"></icon>
        </div>
    </div>

</template>

<script setup>
import {defineProps, onMounted, ref, watch} from "vue";
import FileUpload from "@/views/components/dashboard/fileUpload.vue";
import Icon from "@/views/components/helpers/icon.vue";
import {useRoute} from "vue-router";
import {apiRequest} from "@/utils/helpers";
import {FILES} from "@/router/api";
import useEmitter from "@/utils/useEmitter";
import store from "@/store";

const route = useRoute();
const emitter = useEmitter();
const personal_doc_url = ref(false);
const props = defineProps({
    data: {
        type: [String, Boolean],
        default: false
    }
})

emitter.on('returnUrl', value => {
    if(value.uploadType === 'personalDoc') {
        personal_doc_url.value = value.url;
    }
})

onMounted(async () => {
    personal_doc_url.value = props.data;
});

watch(() => props.data, (newData, oldData) => {
    if(newData !== oldData) {
        personal_doc_url.value = props.data;
    }
});

const deletePersonalDoc = async () => {
    const fd = ref({
        type: 'personalDoc',
        user_uuid: route.params.id,
    });
    await apiRequest(FILES, fd.value, 'delete')
        .catch(e => {
            return console.log("ERROR", e);
        })
        .then(async resp => {
            if (resp && resp.success) {
                personal_doc_url.value = false;
                emitter.emit('personalDocDelete', true);
            }
        })
}



</script>