<template>
    <div class="bg-light-base border rounded-2xl p-3.5 flex flex-col h-full">

        <div class="sm:flex">
            <div class="w-full sm:w-1/3 mr-5 d_center" v-if="props.data.banner_url">
                <img :src="props.data.banner_url" class="w-full rounded-xl" alt="">
            </div>
            <div class="w-full sm:w-2/3 mt-7 sm:mt-0">
                <div class="text-xl text-dark-base font-semibold">
                    {{props?.data?.title}}
                </div>
                <div class="text-base font-normal mt-3">
                    {{props?.data?.direction?.title}}
                </div>
                <div class="text-sm font-normal mt-3 text-grey-text">
                    {{props?.data?.place_info}}
                </div>
                <div class="text-sm font-normal mt-3">
                    {{moment(props?.data?.start_at).format('DD.MM.yy')}}
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
import {defineProps, onMounted, ref} from "vue";
import moment from 'moment';
import useEmitter from "@/utils/useEmitter";

const emitter = useEmitter();
const eventModal = ref(false);
const props = defineProps({
    data: {
        type: Object,
        default: {}
    },
})


</script>
