<template>
    <dashboardLayout>
        <navigation-container class="mt-10">

            <wrap-section v-if="event">
                <img class="rounded-lg" :src="event.banner_url" alt="">

                <h1 class="text-2xl font-normal text-left pt-5">
                    {{ event.title }}
                </h1>

                <h2 class="text-lg font-semibold text-dark-base pt-5">
                    {{ event.direction.name }}
                </h2>

                <timer :registrationStartAt="event.registration_start_at" :registrationEndAt="event.registration_end_at"
                       :eventStartAt="event.start_at" :eventEndAt="event.end_at"
                />

            </wrap-section>

            <wrap-section :is-empty=true>
                <div v-if="registrations.length">
                    <h2 class="text-2xl md:text-4xl ">Оплата</h2>

                    <payment-p-c :registrations="registrations" @delete-registration="deleteRegistration"
                                 class="hidden sm:block" :event="event"/>

                    <payment-mob :registrations="registrations" @delete-registration="deleteRegistration"
                                 class="sm:hidden" :event="event"/>

                    <alerts alertType="paymentEvent" />

                    <div class="block sm:flex justify-between mt-7" v-if="total > 0">
                        <div class="block sm:d_center">
                            <div class="text-xl">
                                Итого: <span class="font-bold text-base-color">
                                {{total.toLocaleString()}} ₽
                            </span>
                            </div>
                        </div>


                        <div class="w-full mt-5 sm:mt-0 sm:w-[260px] ">
                            <button class="btn header_auth btn_white d_center !text-sm p-3 w-full" @click="payInit">
                                <icon icon-name="payment" color-var="--base-color" class="mr-3"/>
                               Оплатить  <!--  всех участников-->
                            </button>
                        </div>

                    </div>
                </div>
                <div v-else>
                    <h2 class="text-2xl md:text-4xl ">Регистраций не найдено, перейдите
                        <a href="" @click="router.push({name: 'event-registration', params: {id: event.uuid}})">по ссылке</a>

                        для регистрации спортсменов</h2>
                </div>

            </wrap-section>
        </navigation-container>
    </dashboardLayout>
</template>

<script setup>
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import {onMounted, ref, computed, watch} from "vue";
import useEmitter from "@/utils/useEmitter";
import {useRoute} from 'vue-router';
import dashboardLayout from "@/views/layout/dashboard";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import RegistrationPC from "@/views/pages/dashboard/registration/registrationPC.vue";
import RegistrationInput from "@/views/pages/dashboard/registration/registrationInput.vue";
import {apiRequest, navigateTo} from "@/utils/helpers";
import {EVENT_PAYMENT, EVENTS, REGISTRATION} from "@/router/api";
import RegistrationMob from "@/views/pages/dashboard/registration/registrationMob.vue";
import PaymentPC from "@/views/pages/dashboard/payment/paymentPC.vue";
import Timer from "@/views/components/competitions/timer.vue";
import Icon from "@/views/components/helpers/icon.vue";
import PaymentMob from "@/views/pages/dashboard/payment/paymentMob.vue";
import Modal from "@/views/components/modal/modal.vue";
import router from "@/router/router";
import Alerts from "@/views/components/helpers/alerts.vue";

const route = useRoute();
const emitter = useEmitter();
const event = ref(null);
const competitionId = ref(route.params.id);
const registrations = ref([]);
const total = ref(0);
const paymentModal = ref(false);

const payInit = () => {
    apiRequest(EVENT_PAYMENT + "?event_uuid=" + route.params.id,[], 'post')
        .then(resp => {
            if (resp.error) {
                emitter.emit("alert", {alertType: 'paymentEvent',
                    message: 'Ошибка обработки платежа. Обратитесь в службу поддержки или попробуйте позднее'});
            }
            if (resp.success && resp.result) {
                window.location.href = resp.result;
            }
        })
        .catch(e => {
            emitter.emit("alert", {alertType: 'paymentEvent', message: e});
        })
}
const registrationData = async () => {
    await apiRequest(REGISTRATION + "?event_uuid=" + route.params.id,[], 'get')
        .catch(e => {
            console.log('error', e)
        })
        .then(resp => {
            if (resp.error) {
                emitter.emit("alert", resp.message);
            }
            if (resp.success && resp.result && resp.result) {
                registrations.value = resp.result;
            }
        })
};

onMounted(async () => {
    await competition();
    await registrationData();
});

const updateRegistrations = (newRegistrations) => {
    registrations.value = newRegistrations;
};

const deleteRegistration = (uuid) => {
    apiRequest(REGISTRATION + "/" + uuid, {uuid: uuid}, 'delete')
        .catch(e => {
            console.log('error', e)})
        .then(resp => {
            if(resp && resp.success && resp.result) {
                registrations.value = registrations.value.filter(reg => reg.uuid !== uuid);
            }
        })
}
const competition = async () => {
    await apiRequest(`${EVENTS}/${competitionId.value}`, null, 'get')
        .catch(e => {
            console.log('error', e)
        })
        .then(resp => {
            if (resp && resp.success && resp.result) {
                event.value = resp.result
            }
        })
}

const calculateTotal = () => {
    if(registrations.value.length) {
        total.value = 0;
        registrations.value.forEach(el => {
            if(!el.is_paid) {
                total.value += parseFloat(el?.direction_category_price)
            }
        })
    }
};

watch([registrations, event], calculateTotal, { deep: true });

</script>
