<template>
    <div class="mx-auto w-fit flex" v-if="meta && meta.last_page && meta.last_page !== 1">
        <button @click="loadPage(meta.prev_url)" class="mr-1" :disabled="!meta.prev_url">
            <icon icon-name="paginationPrew" />
        </button>

        <button @click="loadPage(meta.first_url)"
                class="text-sm rounded-lg border-light-border border-solid border py-1.5 px-3 mx-1"
                :class="{ 'text-white bg-base-color': meta.current_page === 1, 'text-black bg-white': meta.current_page !== 1 }">
            1
        </button>

        <div v-if="meta.current_page > 4">
            <button class="text-sm rounded-lg py-1.5 mx-0.5">...</button>
        </div>

        <button v-for="link in pageLinks" :key="link.label" @click="loadPage(link.url)"
                class="text-sm rounded-lg border-light-border border-solid border py-1.5 px-3 mx-1"
                :class="{ 'text-white bg-base-color': link.active, 'text-black bg-white': !link.active }">
            {{ link.label }}
        </button>

        <div v-if="meta.current_page < meta.last_page - 3">
            <button class="text-sm rounded-lg py-1.5 mx-0.5">...</button>
        </div>

        <button v-if="meta.last_page > 1" @click="loadPage(meta.last_url)"
                class="text-sm rounded-lg border-light-border border-solid border py-1.5 px-3 mx-1"
                :class="{ 'text-white bg-base-color': meta.current_page === meta.last_page, 'text-black bg-white': meta.current_page !== meta.last_page }">
            {{ meta.last_page }}
        </button>

        <button @click="loadPage(meta.next_url)" class="ml-1" :disabled="!meta.next_url">
            <icon icon-name="paginationNext" />
        </button>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import Icon from "@/views/components/helpers/icon.vue";
import { defineProps } from "vue";

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
    meta: {
        type: Object,
        required: true,
    },
});

const pageLinks = ref([]);

const loadPage = (url) => {
    if (url) {
        const urlParams = new URLSearchParams(url.split('?')[1]);
        const page = urlParams.get('page');
        emit('update:modelValue', parseInt(page));
    }
};

watch(
    () => props.meta,
    (newMeta) => {
        if (newMeta && newMeta.links && newMeta.links.length > 2) {
            pageLinks.value = newMeta.links.slice(1, -1);
        } else {
            pageLinks.value = [];
        }
    },
    { immediate: true }
);
</script>
