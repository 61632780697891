<template>
    <div class="mt-5">
        <input-search v-model="search" @input="searchHandler" label="Добавить представителей" />

        <div class="divide-y divide-light-border divide-solid mt-7">
            <div v-for="el in data"  :key="el.uuid" v-if="data.length" >
                <represent-search-item :data="el" />
            </div>
        </div>
<!--                <button class="btn mt-8 btn_blue w-full sm:w-64 " @click="" v-if="data.length">-->
<!--                    Сохранить-->
<!--                </button>-->
    </div>



</template>

<script setup>
import InputSearch from "@/views/components/inputComponent/inputSearch.vue";
import {ref} from "vue";
import {apiRequest} from "@/utils/helpers";
import {REPRESENT} from "@/router/api";
import RepresentSearchItem from "@/views/components/createCompetition/representSearchItem.vue";
import {useRoute} from "vue-router";
const route = useRoute();
const search = ref('');
const data = ref([]);

const searchHandler = () => {
    if(!search.value) return;
    representSearch();
}
const representSearch = async () => {
    try {
        const response = await apiRequest(REPRESENT, { params: { represent_search: search.value, event: route.params.id } }, 'get');
        if (response && response.success && response.result) {
            data.value = response.result;
        }
    } catch (e) {
        console.error('Ошибка при загрузке данных о спортсменах:', e);
    }
};

defineExpose({
    representSearch,
});
</script>