<template>
    <div class="mt-7 mb-7 flex justify-between">
        <div class="w-full">
            <base-user-info :data="data"/>
            <input-custom label="Дожность" type="text" v-model="description" class="mt-4 mob_margin"
                          labelClass="pass-update-org"/>
        </div>

        <div class="text-right relative">
            <button @click="deleteRepresent" class="hidden" :class="{ 'hidden': isLoading, 'md:block': !isLoading }" >
                <span class="text-sm text-base-color hidden md:inline whitespace-nowrap">Удалить из списка</span>
            </button>
            <div class="md:hidden">
                <div class="h-11 w-11 bg-danger-light rounded-2xl cursor-pointer d_center" @click="deleteRepresent">
                    <icon icon-name="delete" />
                </div>
            </div>

            <div v-if="isLoading" class="loader ml-1 hidden md:inline-block"></div>
        </div>

    </div>

</template>

<script setup>
import {defineProps, onMounted, ref} from "vue";
import BaseUserInfo from "@/views/components/profile/baseUserInfo.vue";
import icon from "@/views/components/helpers/icon.vue";
import {apiRequest} from "@/utils/helpers";
import {REPRESENT} from "@/router/api";
import useEmitter from "@/utils/useEmitter";
import InputCustom from "@/views/components/inputComponent/inputCustom.vue";
import Icon from "@/views/components/helpers/icon.vue";

const emitter = useEmitter();
const description = ref('');
const isLoading = ref(false);
const is_represent = ref(false);
const props = defineProps({
    data: Object,
    representId: Number,
});

const deleteRepresent = () => {
    if (isLoading.value) return;
    isLoading.value = true;
    apiRequest(REPRESENT + '/' + props.representId, '', 'delete')
        .then(resp => {
            if (resp && resp.success ) {
                is_represent.value = true;
                emitter.emit('represent_update');
            }
        })
        .catch(e => {
            console.log('error', e)
        })
        .finally(() => {
            isLoading.value = false;
        });
}

onMounted(() => {
    if(props.data.represent_desc) {
        description.value = props.data.represent_desc;
    }
})

const updateJob = () => {
    apiRequest(REPRESENT + '/' + props.data.represent_id, {type: 'updateJob', description: description.value}, 'put')
        .then(resp => {})
        .catch(e => {
            console.log('error', e)
        })

}

emitter.on('represent_update_job', () => {
    if(description.value && description.value.length) {
        updateJob();
    }
})


</script>