<template>
    <div v-if="showModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
         @click="closeModalOutside">
        <div class="bg-white p-7 rounded-2xl shadow-lg w-full m-5 sm:max-w-96 sm:m-0 relative">
            <icon icon-name="closeBlue" class="absolute right-8 top-8 cursor-pointer"
                  @click="closeModal"></icon>
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
import {defineProps, ref, watch} from 'vue';
import Icon from "@/views/components/helpers/icon.vue";
import useEmitter from "@/utils/useEmitter";

const emitter = useEmitter();
const props = defineProps({
    visible: Boolean,
    default: false
});
let showModal = ref(false);

watch( () => props.visible, () => {
    if(props.visible) {
        openModal();
    } else {
        closeModal();
    }
})

const closeModal = () => {
    showModal.value = false;
    emitter.emit("closeModal");
    document.documentElement.classList.remove('overflow-hidden');
};

const openModal = () => {
    showModal.value = true;
    document.documentElement.classList.add('overflow-hidden');
}

const closeModalOutside = (event) => {
    if (event.target.classList.contains('bg-black')) {
        closeModal();
    }
};

</script>
