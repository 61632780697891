<template>
    <dashboardLayout>
        <navigation-container class="mt-10">

            <wrap-section class="mt-10" v-if="paymentSuccess">
                <h1>
                    Спасибо. Успешная оплата
                </h1>

            </wrap-section>
        </navigation-container>
    </dashboardLayout>
</template>

<script setup>
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import {onMounted, ref} from "vue";
import dashboardLayout from "@/views/layout/dashboard";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import {useRoute} from "vue-router";
import {apiRequest} from "@/utils/helpers";
import {EVENT_PAYMENT_SUCCESS} from "@/router/api";

const paymentSuccess = ref(false);

onMounted(async () => {
    const route = useRoute();
    const orderId = route.query.orderId;

    if (orderId) {
        try {
            apiRequest(EVENT_PAYMENT_SUCCESS + "?pay_id=" + orderId,[], 'post')
                .then(resp => {
                    if (resp.error) {
                        console.log(resp)
                    }
                    if (resp.success) {
                        paymentSuccess.value = true;
                    }
                })
                .catch(e => {
                    console.log(e)
                })
        } catch (error) {

        }
    }
});


</script>
