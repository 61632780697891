<template>
    <dashboardLayout>
        <navigation-container class="mt-10">

            <wrap-section :is-empty="true">
                <h2 class="text-2xl sm:text-4xl font-semibold text-dark-base">
                    Регистрация спортсменов
                </h2>
                <div class="mt-5 mb-5 text-lg hidden sm:block">
                    После завершения регистрации вам потребуется оплатить участие спортсменов. После оплаты все зарегистрированные участники появятся в разделе «Мои соревнования», где вы сможете загрузить необходимые файлы для участия в соревновании.
                </div>

                <registration-p-c :registrations="registrations" @delete-registration="deleteRegistration"
                                  v-if="registrations.length" class="hidden sm:block"/>
                <registration-mob :registrations="registrations" @delete-registration="deleteRegistration"
                                  v-if="registrations.length" class="sm:hidden"/>

            </wrap-section>

            <registration-input class="mt-7" :registrations="registrations" @update:registrations="updateRegistrations" />

        </navigation-container>
    </dashboardLayout>
</template>

<script setup>
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import {onMounted, ref, computed} from "vue";
import useEmitter from "@/utils/useEmitter";
import {useRoute} from 'vue-router';
import dashboardLayout from "@/views/layout/dashboard";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import RegistrationPC from "@/views/pages/dashboard/registration/registrationPC.vue";
import RegistrationInput from "@/views/pages/dashboard/registration/registrationInput.vue";
import {apiRequest} from "@/utils/helpers";
import {REGISTRATION} from "@/router/api";
import RegistrationMob from "@/views/pages/dashboard/registration/registrationMob.vue";

const route = useRoute();
const emitter = useEmitter();
const event = ref(null);
const registrations = ref([]);

const registrationData = async () => {
    await apiRequest(REGISTRATION + "?event_uuid=" + route.params.id,[], 'get')
        .catch(e => {
            console.log('error', e)
        })
        .then(resp => {
            if (resp.error) {
                emitter.emit("alert", resp.message);
            }
            if (resp.success && resp.result && resp.result) {
                registrations.value = resp.result;
            }
            console.log(resp)
        })
};

onMounted(async () => {
    await registrationData();
});

const updateRegistrations = (newRegistrations) => {
    registrations.value = newRegistrations;
};

const deleteRegistration = (uuid) => {
    apiRequest(REGISTRATION + "/" + uuid, {uuid: uuid}, 'delete')
        .catch(e => {
            console.log('error', e)})
        .then(resp => {
            if(resp && resp.success && resp.result) {
                registrations.value = registrations.value.filter(reg => reg.uuid !== uuid);
            }
        })
}

</script>
