<template>
  <div class="wrap_input">

    <div class="text-sm flex justify-normal items-center pass-update mb-2" v-if="!noLabel">
      {{ label }}
    </div>
      <div class="relative w-full">
          <input
              :disabled="disabled"
              class="relative w-full text-sm border rounded-2xl p-4 h-11 border-dark-border focus:outline-none focus:border focus:border-base-color focus:shadow-base"
              v-model="inputValue"
              type="text"
              @input="onInput"
          >
          <icon icon-name="ruble" class="absolute right-4 top-3 w-5 h-5"></icon>
      </div>



  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import Icon from "@/views/components/helpers/icon.vue";

const props = defineProps({
    modelValue: {
        type: String,
        default: '0'
    },
    label: {
        type: String,
        required: true,
    },
    noLabel: {
        default: false,
        type: Boolean,
    },
    disabled: {
        default: false,
        type: Boolean,
    }
});

const emit = defineEmits(['update:modelValue']);

const inputValue = ref('');

watch(() => props.modelValue, (newValue) => {
    if (parseInt(inputValue.value) !== parseInt(newValue) && parseInt(newValue) !== 0) {
        inputValue.value = parseInt(newValue)
    }
}, { deep: true, immediate: true });

const onInput = (event) => {
    const rawValue = event.target.value.replace(/\D/g, '');
    inputValue.value = rawValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    emit('update:modelValue', rawValue);
};

</script>


<style scoped>
.pass-update {
    font-weight: bold;
    flex: 0 0 130px;
    white-space: nowrap;
}
.wrap_input {
  display: flex;
}

@media (max-width: 576px) {
  .wrap_input {
    display: block;
  }
  input {
    padding-right: 10%;
  }
}
@media (max-width: 490px) {
    input {
        padding: 0 10px;
    }
}



</style>
