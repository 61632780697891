<template>
    <div class="sidebar-padding flex cursor-pointer mt-5 text-white text-center w-full" @click="logout" :class="addClass">
        Выйти из аккаунта
        <div class="flex items-center ml-3 sidebar_icon" :class="{ 'absolute right-10': addClass }">
            <icon icon-name="logout"/>
        </div>
    </div>
</template>

<script>
import {logout, navigateTo} from "@/utils/helpers";
import { mapGetters } from "vuex";
import Icon from "@/views/components/helpers/icon.vue";
export default {
    methods: {logout, navigateTo },
    components: { Icon },
    computed: mapGetters(['getUserAvatar', 'getUserEmail', 'getUserFio', 'isMinHeight', 'isMobile']),

    props: {
        addClass: {
            type: String,
            default: null
        }
    },
}
</script>
