<template>
    <dashboardLayout>
        <navigation-container class="mt-10">

            <wrap-section v-if="eventInfo">
                <div v-if="eventInfo.banner_url">
                    <img :src="eventInfo.banner_url" alt="" class="rounded-2xl">
                </div>
                <div class="text-2xl font-normal leading-7 mt-5">
                    {{eventInfo.title}}
                </div>
                <div class="text-dark-base text-xl mt-5 font-bold">
                    {{eventInfo.direction.name}}
                </div>


            </wrap-section>

            <wrap-section :is-empty=true>
                <h2 class="text-xl font-bold md:text-4xl text-dark-base">Файлы</h2>
            </wrap-section>

            <comp-pc class="hidden mi:block" :data="sportsmanFilesUploadList"/>
            <comp-mob class="mi:hidden" :data="sportsmanFilesUploadList"/>
            <br><br>
        </navigation-container>
    </dashboardLayout>
</template>

<script setup>
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import CompPc from "@/views/components/competitions/compPC.vue";
import CompMob from "@/views/components/competitions/compMob.vue";
import {SPORTSMANS_FILES_UPLOAD} from "@/router/api";
import {apiRequest} from "@/utils/helpers";
import {onMounted, ref} from "vue";
import useEmitter from "@/utils/useEmitter";
import {useRoute} from 'vue-router';
import dashboardLayout from "@/views/layout/dashboard";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";

const route = useRoute();
const emitter = useEmitter();
const sportsmanFilesUploadList = ref(null);
const eventInfo = ref(null);
const competitionId = ref(route.params.id);

onMounted(async () => {
    await compFilesUpload();
});
const compFilesUpload = async () => {
    await apiRequest(SPORTSMANS_FILES_UPLOAD + '?event_id=' + competitionId.value, null, 'get')
        .catch(e => {
            console.log('error', e)
        })
        .then(resp => {
            if (resp && resp.success && resp.result?.data) {
                sportsmanFilesUploadList.value = resp.result.data
                eventInfo.value = resp.result.event
            }
        })
}
emitter.on('fileSportsmanUpload', value => {
    if (!value) return;
    console.log('fileSport upload', value)
    sportsmanFilesUploadList.value = value;
})

</script>
