<template>
    <dashboardLayout>
        <div class="w-full pt-12 md:pt-0 mt-10 md:mt-0">
            <button class="btn btn_white d_center back_btn header_auth" @click="navigateTo('dashboard')"
                    v-if="!store.state.isMobile">
                <icon icon-name="back" class="mr-7"/>
                Вернуться на личную страницу
            </button>
            <div class="flex justify-center mb-10" v-if="users.length">

                <div class="wrap_dashboard">
                    <h2>Пользователей для верификации <b>{{ total }}</b></h2>
                    <div class="">
                        <wrap-section v-for="user in users">
                            <div class="flex">
                                <div class="flex-1">
                                    <div>
                                        <b class="mr-3">Имя:</b> {{ user?.name }}
                                    </div>
                                    <div>
                                        <b class="mr-3">Фамилия:</b> {{ user?.surname }}
                                    </div>
                                    <div>
                                        <b class="mr-3">Отчество:</b> {{ user?.middle_name }}
                                    </div>
                                </div>
                                <div class="flex-1">
                                    <div>
                                        <b class="mr-3">Создан:</b> {{ dateToLocal(user?.created_at) }}
                                    </div>
                                    <div>
                                        <b class="mr-3">Дата рождения:</b> {{ dateToLocal(user?.birthday) }}
                                    </div>
                                    <div>
                                        <b class="mr-3">Пол:</b> {{ getGender(user?.sex) }}
                                    </div>
                                </div>
                            </div>

                            <div class="pt-5 pb-5"><img :src="user?.media[0].original_url" alt="" class="w-full"></div>
                            <span class="mt-8">В случае отклонения, укажите причину:</span>

                            <input-custom label='' type="text" :no-label="true" :id=user?.uuid></input-custom>
                            <alerts :alertType=user?.uuid class="mt-5 mb-5"/>
                            <div class="flex justify-between mt-7">
                                <button class="btn mr-5 btn_white" @click="cxlVerification(user?.uuid)">Отклонить</button>
                                <button class="btn ml-5 btn_blue" @click="acceptVerification(user?.uuid)">Принять</button>
                            </div>
                        </wrap-section>
                    </div>

<!--                    <pagination :pages="pages" class="mt-8"/>-->

                    <div class="h-32"></div>
                </div>
            </div>
            <div v-else>
                <h1>Все пользователи верифицированы</h1>
            </div>
        </div>
    </dashboardLayout>
</template>

<script setup>

import dashboardLayout from "@/views/layout/dashboard";
import {apiRequest, dateToLocal, getGender, navigateTo} from "@/utils/helpers";
import Icon from "@/views/components/helpers/icon.vue";
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import store from "@/store";
import {VERIFICATION} from "@/router/api";
import {ref} from "vue";
import InputCustom from "@/views/components/inputComponent/inputCustom.vue";
import useEmitter from "@/utils/useEmitter";
import Alerts from "@/views/components/helpers/alerts.vue";
import Pagination from "@/views/components/helpers/pagination.vue";

const emitter = useEmitter();

let users = ref([]);
let total = ref([]);
let pages = ref([]);

let needVerifications = () => {
    apiRequest(VERIFICATION, null, 'get')
        .catch(e => {
            console.error(e)
        })
        .then((result) => {
            if (result?.success) {
                users.value = result.user?.data;
                total.value = result.user?.total;
                pages.value = result.user?.links;
            } else {
            }
        });
}
needVerifications();

const cxlVerification = (id) => {
    let value = document.getElementById(id)?.querySelector('input').value;
    if (value?.length < 10) return emitter.emit("alert", {
        alertType: id,
        message: "Укажите причину отклонения валидации"
    });
}

const acceptVerification = (id) => {
    let base = 'Не удалось верифицировать пользователя'
    apiRequest(VERIFICATION, {uuid: id}, 'put')
        .catch(e => {
            emitter.emit("alert", {alertType: id, message: base});
            console.error(e)
        })
        .then((result) => {
            if (result?.success) {
                users.value = result.user?.data;
                total.value = result.user?.total;
                pages.value = result.user?.links;
            } else {
                emitter.emit("alert", {alertType: id, message: base});
                console.error(result)
            }
        });

}

</script>

<style scoped>
.back_btn {
    margin: 40px 30px;
    width: 380px;
    font-size: 16px;
}


</style>
