<template>
    <div class="flex ">
        <icon icon-name="uploadDoc" class="w-5 h-5 mr-3"></icon>
        <div class="d_center">
            <a :href="props.data.original_url">
                {{ props.data.file_name }}
            </a>
        </div>
    </div>
    <button @click="deleteEventDoc" :disabled="isLoading"
            :class="{ 'hidden': isLoading, 'md:inline': !isLoading }">
        <span class="text-sm text-base-color hidden md:inline">Удалить</span>
    </button>
    <div v-if="isLoading" class="loader inline-block ml-1"></div>

</template>

<script setup>

import {defineProps, onMounted, ref} from "vue";
import useEmitter from "@/utils/useEmitter";
import {apiRequest} from "@/utils/helpers";
import {EVENT_DOC} from "@/router/api";
import Icon from "@/views/components/helpers/icon.vue";

const isLoading = ref(false);
const data = ref([]);
const emitter = useEmitter();

const props = defineProps({
    eventUuid: {
        type: String,
        default: 1
    },
    data: {
        type: Object,
        default: {}
    }
});

const deleteEventDoc = () => {
    if (isLoading.value) return;
    isLoading.value = true;
    apiRequest(EVENT_DOC + '/' + props.data.id, {event_id: props.eventUuid}, 'delete')
        .then(resp => {
            if (resp && resp.success && resp.result) {
                emitter.emit("deleteEventDoc", {success: true, id: props.data.id})
            }
        })
        .catch(e => {
            console.log('error', e)
        })
        .finally(() => {
            isLoading.value = false;
        });
}


</script>