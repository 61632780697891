<template>
    <div class="wrap_sidebar h-screen flex-col justify-between hidden md:flex fixed pt-7 pb-7">
        <div class="sidebar_item">
            <div class="w-full flex place-content-center">
                <img src="/logo_fed_old.svg" alt="" class="sidebar_logo cursor-pointer" @click="navigateTo('dashboard')">
            </div>
            <div class="w-full sidebar_line mt-7"></div>
            <sidebar-avatar />
            <sidebar-menu />
        </div>
        <sidebar-logout />
    </div>
</template>

<script>
import {navigateTo, logout} from "@/utils/helpers";
import SidebarMenu from "@/views/layout/components/sidebar-menu.vue";
import {mapGetters} from "vuex";
import Icon from "@/views/components/helpers/icon.vue";
import SidebarAvatar from "@/views/layout/components/sidebar-avatar.vue";
import SidebarLogout from "@/views/layout/components/sidebar-logout.vue";
export default {
    methods: {navigateTo, logout},
    components: {SidebarLogout, SidebarAvatar, Icon, SidebarMenu},
    computed: mapGetters(['getUserAvatar', 'getUserEmail', 'getUserFio', 'isMinHeight'])

}
</script>

<style scoped>
    .sidebar_logo {
        width: 240px;
    }
    .wrap_sidebar {
        overflow-y: auto;
        width: 315px;
        background: radial-gradient(165.76% 50% at 21.11% 104.81%, var(--base-color) 0%, var(--grad-dark) 100%);
    }

</style>