<template>
    <div class="flex">
        <div class="w-20 h-20 sm:w-24 sm:h-24">
            <img v-if="data.avatar" class="rounded-full w-full h-full object-cover" :src="data.avatar" alt="">
            <img v-else class="rounded-full w-full" :src="avatar" alt="">
        </div>
        <div class="flex-auto sm:w-72 text-left ml-2.5	sm:ml-7">
            <div class="name font-medium text-xs md:text-lg">
                {{ data.name }} <br class="sm:hidden"/>
                {{ data.surname }}
                <icon :icon-name="isAuthorised" class="inline-block ml-2.5 -mb-0.5"/>
            </div>
            <div class="sex_age font-normal text-xs md:text-sm mt-2.5 flex">
                <div class="d_center mr-2">
                    <icon icon-name="figure"/>
                </div>
                {{ sex }}, {{ calculateAge(data.birthday) }}
            </div>
            <div class="sex_age font-normal text-xs md:text-sm mt-2.5 flex"
                 v-if="data.organisations && data.organisations.length">
                <div class="d_center mr-2">
                    <icon icon-name="briefcase"/>
                </div>
                {{ userOrganisations }}
            </div>

            <div class="mt-2.5 font-normal text-xs md:text-sm">{{ data.city }}</div>

        </div>
    </div>
</template>

<script>
import avatarMale from "@/../public/img/avatar/male.svg";
import avatarFemale from "@/../public/img/avatar/female.svg";
import icon from "@/views/components/helpers/icon.vue";
import {calculateAge} from "@/utils/helpers";
import store from "@/store";

export default {
    data: () => {
        return {
            avatarMale: avatarMale,
            avatarFemale: avatarFemale,
        }
    },
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    components: {
        icon
    },
    computed: {
        sex() {
            return this.data.sex === 'male' ? 'Муж' : 'Жен';
        },
        avatar() {
            return this.data.sex === 'male' ? avatarMale : avatarFemale;
        },
        isAuthorised() {
            if (this.data.status === 'active') {
                return 'authorised';
            } else if (this.data.status === 'moderating') {
                return 'moderating';
            }
            return 'notAuthorised';
        },
        userOrganisations() {
            const organisationNames = this.data.organisations.map(org => org.name);
            return organisationNames.join(', ');
        }
    },
    methods: {
        calculateAge
    }
}
</script>
