<template>
    <dashboardLayout>
        <navigation-container :navigation-data="navData"
                              :btn="{title: 'Вернуться на личную страницу', route: 'dashboard'}">
            <modal>
                <div class="text-left text-base-color font-bold text-lg">Привет</div>
                <div class="text-sm mt-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid aspernatur blanditiis commodi, facilis impedit itaque obcaecati porro provident quia quis, sit tempore tenetur. Dolorum eveniet laborum maiores mollitia repellendus veritatis!</div>
                <button class="btn btn_blue w-full mt-5">Hello</button>
            </modal>
            <div class="flex justify-center mb-10">
                <div class="w-full lg:w-auto">
                    <div class="prof_container relative flex">
                        <wrap-section :is-empty=true >
                            <h2 class="text-2xl md:text-4xl ">Редактирование профиля</h2>
                            <p class="text-base mt-5">Заполните обязательную информацию о себе для участия в
                                соревнованиях</p>
                        </wrap-section>
                    </div>
                    <red-alert v-if="alertRef" :info="bannerMessage" alertType="error" />
                    <div class="prof_container relative flex" id="photo">
                        <wrap-section>
                            <span class="text-dark-base text-xl font-bold">Фото</span><br><br>
                            <div class="flex flex-wrap sm:flex-nowrap">
                                <div v-if="store.getters.getUserAvatar.isSet"
                                     class="sm:mr-5 flex items-center justify-center w-28 h-28 sm:w-32 mx-auto">
                                    <img :src="store.getters.getUserAvatar.url" alt="" class="rounded-full">
                                </div>
                                <div class="w-full mt-4 sm:mt-0">
                                    <span class="font-bold">Загрузите фотографию</span> <br>
                                    <span class="text-base">В форматах JPG, PNG</span>
                                    <div class="mt-3">
                                        <file-upload uploadType="avatar" message="Ошибка при загрузке Аватара"/>
                                    </div>
                                </div>
                            </div>
                        </wrap-section>
                    </div>
                    <div class="prof_container mt-8" id="personal">
                        <wrap-section>
                            <div class="flex justify-between">
                                <span class="text-dark-base text-xl font-bold">Персональные данные</span>
                                <div class="d_center">
                                    <validate-modal />
                                </div>
                            </div>
                            <br><br>
                            <personal-data/>
                        </wrap-section>
                    </div>
                    <div class="prof_container mt-8" id="role">
                        <add-roles/>
                    </div>
                    <div class="prof_container mt-8" id="password">
                        <change-password/>
                    </div>

                </div>
            </div>
        </navigation-container>
    </dashboardLayout>
</template>

<script setup>
import dashboardLayout from "@/views/layout/dashboard";
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import FileUpload from "@/views/components/dashboard/fileUpload.vue";
import store from "@/store";
import AddRoles from "@/views/components/dashboard/addRoles.vue";
import ChangePassword from "@/views/components/dashboard/changePassword.vue";
import PersonalData from "@/views/components/dashboard/personalData.vue";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import Modal from "@/views/components/modal/modal.vue";
import ValidateModal from "@/views/components/modal/validateModal.vue";


const navData = [
    {name: 'Фото', id: 'photo'},
    {name: 'Персональные данные', id: 'personal'},
    {name: 'Роль на платформе', id: 'role'},
    {name: 'Пароль', id: 'password'},
];

</script>


