<template>
    <div class="w-full">
        <div class="px-3 sm:px-6 lg:px-8 hidden md:block" v-if="btn">
            <button class="btn btn_white mt-8 py-2.5 px-3 !text-sm d_center" @click="navigateTo(btn.route)" >
                <icon icon-name="back" class="mr-3"/>
                {{ btn.title }}
            </button>
        </div>
        <div class="flex w-full justify-center relative pt-14 md:pt-0">
            <div class="px-3 sm:px-6 lg:px-8 w-full">
                <div class="flex justify-between items-start gap-6" :class="{ '!justify-center': !navigationData }">
                    <div  class="w-full" :class="{ 'md:w-auto': !navigationData, 'lg:w-3/4': navigationData }" >
                        <red-alert v-if="store.state.user.status==='draft' && alertRef" :info="bannerMessage" 
                        headerText="Для завершения регистрации, откройте раздел «Редактировать профиль» и заполните данные:" alertType="error" />
                        <slot />
                        <div :class="{ 'h-20': !store.state.isMinHeight, 'h-10': store.state.isMinHeight }"></div>
                    </div>
                    <div class="hidden lg:block w-1/4" v-if="navigationData">
                        <div class="sticky top-0 h-screen overflow-y-auto">
                            <navigation class="text-base font-bold" :navigationData="navigationData"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Navigation from "@/views/components/helpers/navigation.vue";
import Icon from "@/views/components/helpers/icon.vue";
import {navigateTo} from "@/utils/helpers";
import store from "@/store";
import { computed, ref, watch } from 'vue';
import RedAlert from "@/views/components/helpers/redAlert.vue";

const props = defineProps({
    navigationData: {
        type: Array,
        default: null,
    },
    btn: {
        title: Object,
        default: null,
    },
})

const alertRef = ref(false);

const bannerMessage = ref('');

const user = computed(() => store.state.user);

function checkRequiredFields() {
    const messages = [];

    if (!user.value.middle_name) messages.push('Введите отчество');
    if (!user.value.birthday) messages.push('Введите дату рождения');
    if (!user.value.location?.city) messages.push('Введите город');
    if (user.value.organisations.length === 0) messages.push('Выберите организацию');
    if (!user.value.personal_doc) messages.push('Загрузите документ для подтверждения личности');

    bannerMessage.value = messages;

    alertRef.value = bannerMessage.value.length > 0;
}


watch(user, checkRequiredFields, { immediate: true });



</script>




