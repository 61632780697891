import {createApp} from 'vue'
import App from './App.vue'
import mitt from 'mitt';
import store from "@/store";
import router from "./router/router";

const emitter = mitt();
const app = createApp(App);

app.config.globalProperties.emitter = emitter;

const mediaQuery = window.matchMedia('(max-width: 768px)')
function handleScreenChange(e) {
    store.commit('updateIsMobile', e.matches)
}
mediaQuery.addEventListener('change', handleScreenChange)

handleScreenChange(mediaQuery)

const checkScreenHeight = () => {
    store.commit('updateMinHeight', Boolean(window.innerHeight < 840));
};
checkScreenHeight();
window.addEventListener('resize', checkScreenHeight);


app.use(router)
   .use(store)
   .mount('#app');
