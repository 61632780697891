import router from "@/router/router";
import axios from "axios";
import {
    LOGOUT,
    ORGANISATIONS,
    DIRECTIONS,
    SANCTUM,
    SPORTSMAN,
    SPORTSMANS,
    USER_DATA,
    REGISTRATION_OPTIONS,
    ORGANISATIONS_SEARCH,
    DIRECTION_CATEGORIES,
    EVENTS
} from "@/router/api";
import store from "@/store";
import {roles} from "@/utils/roles";
import useEmitter from "@/utils/useEmitter";

const axiosConfig = {
    withCredentials: true,
    withXSRFToken: true,
    headers: {
        "Accept": "application/json"
    }
};
axios.defaults = Object.assign(axios.defaults, axiosConfig);

// export const navigateTo = (urlName, url = null) => {
//     if(url) {
//         router.push(url);
//     } else {
//         router.push({name: urlName})
//     }
// }


export const navigateTo = (urlName, openInNewTab = false) => {
    if (openInNewTab) {
        window.open(urlName, '_blank');
    } else {
        router.push({ name: urlName });
    }
};



export const apiRequest = (url, data = null, method = 'post') => {
    return new Promise((resolve, reject) => {
        axios.get(SANCTUM)
            .catch((e) => {
                reject(new Error("Error in SANCTUM: " + e.message));
            })
            .then(() => {
                const axiosRequest = axios[method.toLowerCase()];
                axiosRequest(url,  method === 'delete' ? { data } : data)
                    .catch((error) => {
                        console.log('reject helpers.js', error);
                        if (error.response) {
                            if(error.response.data?.error_code === 'forbidden') return navigateTo('login');
                            if(error.response.data?.message) reject(new Error(error.response.data.message));
                            reject(new Error(error.response.data.message));
                        } else if (error.request) {
                            reject(new Error("No response from server"));
                        } else {
                            reject(new Error("Error: " + error.message));
                        }
                    })
                    .then((response) => {
                        if (response?.data) {
                            resolve(response.data);
                        } else {
                            reject(new Error("No data from server"));
                        }
                    });
            });

    });
};

export const logout = () => {
    apiRequest(LOGOUT).then(result => {
        if (result.success) {
            store.commit('logout')
            navigateTo('login');
        }
    })
}

export const uuid = () => {
    const array = new Uint8Array(16);
    window.crypto.getRandomValues(array);

    array[6] = (array[6] & 0x0f) | 0x40;
    array[8] = (array[8] & 0x3f) | 0x80;

    const hex = [...array].map(b => b.toString(16).padStart(2, '0')).join('');
    return `${hex.substr(0, 8)}-${hex.substr(8, 4)}-${hex.substr(12, 4)}-${hex.substr(16, 4)}-${hex.substr(20, 12)}`;
}
export const calculateAge = (birthday) => {
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const dateToLocal = (birthday) => {
    if (!birthday) return false;
    let parts = birthday.split("T");
    if (parts.length === 2) {
        parts = parts[0].split("-");
    } else {
        parts = birthday.split("-");
    }
    if (parts.length > 2) {
        return parts[2] + "." + parts[1] + "." + parts[0];
    } else {
        return false;
    }
}

export const getGender = (value) => {
    if(value === 'male') return "Муж.";
    if(value === 'female') return "Жен.";
    return null;
}


export const isLocalhost = () => {
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1")
        return true;
}

export const getOrganisations = async () => {
    await apiRequest(ORGANISATIONS, null, 'get')
        .catch(e => {
            console.log('error', e)
        })
        .then(resp => {
            if(resp.success && resp.result.data.length) {
                store.commit('setOrganisation', resp.result.data);
            }
        })
}

export const searchOrganisations = async (searchTerm) => {
    try {
        const params = { params: { organisation: searchTerm } };
        
        const response = await apiRequest(ORGANISATIONS_SEARCH, params, 'get');
        
        if (response && response.success) {
            store.commit('setOrganisation', response.result);
            return response;
        } else {
            console.error('Поиск организаций не удался:', response.message);
            return null;
        }
    } catch (error) {
        console.error('Ошибка при поиске организаций:', error);
        return null;
    }
}

export const getUpcomingEvents = (page = 1) => {
    const emitter = useEmitter();
    const self_event = store.state.permissions.includes('public_create_event') ? '' : '&self=true'
    apiRequest(EVENTS + "?event_time=future" + self_event + "&page=" + page, null, 'get')
        .catch(e => {
            console.log('error', e)
        })
        .then(resp => {
            if (resp && resp.success) {
                if(page === 1) {
                    store.commit('upcomingEvents', resp);
                }
                emitter.emit("upcomingEvents", resp.result);
            }
        })
}

export const getDirections = async () => {
    await apiRequest(DIRECTIONS, null, 'get')
        .catch(e => {
            console.log('error', e)
        })
        .then(resp => {
            if(resp.success && resp.result.length) {
                store.commit('setDirection', resp.result);
            }
        })
}

export const getMyStoreSportsman = (qnt = 5) => {
    return store.state.sportsman && store.state.sportsman.length > 0
        ? store.state.sportsman.slice(0, qnt)
        : [];
}

export const getSportsman = async (currentPage) => {
    await apiRequest(SPORTSMAN, {params: {page: currentPage, paginate: 1000}}, 'get')
        .catch(e => {
            console.log('error', e)
        })
        .then(resp => {
            if(resp && resp.success && resp.result?.data) {
                // console.log(resp)
                store.commit('setSportsman', resp.result.data);
                return resp;
            }
        })
}

// export const getRegistrationOptions = async () => {
//     await apiRequest(REGISTRATION_OPTIONS + "?direction_id=1",[], 'get')
//         .catch(e => {
//             console.log('error', e)
//         })
//         .then(resp => {
//             if (resp.error) {
//                 emitter.emit("alert", resp.message);
//             }
//             if (resp.success && resp.result) {
//                 store.commit('setRegistrationOptions', resp.result);
//             }
//         })
// };

export const getDirectionCategories = async () => {
    await apiRequest(DIRECTION_CATEGORIES,[], 'get')
        .catch(e => {
            console.log('error', e)
        })
        .then(resp => {
            if (resp.error) {
                emitter.emit("alert", resp.message);
            }
            if (resp.success && resp.result) {
                store.commit('setDirectionCategories', resp.result);
            }
        })
};


export const getSportsmans = async (currentPage) => {
    await apiRequest(SPORTSMANS, {params: {page: currentPage}}, 'get')
        .catch(e => {
            console.log('error', e)
        })
        .then(resp => {
            if(resp && resp.success && resp.sportsmans) {
                store.commit('setSportsmans', resp.sportsmans);
                return resp;
            }
        })
}

export const getAvatar = (user) => {
    if(user.avatar) return user.avatar;
    if(user.sex === 'male') return '/img/avatar/male.svg';
    return '/img/avatar/female.svg';
}

export const personalDataUpdate = async () => {
    await apiRequest(USER_DATA, null, 'get')
        .catch(e => {
            console.log('error', e)
        })
        .then(resp => {
            if(resp && resp.success && resp.result) {
                store.commit('login', resp.result);
            }
        })
}

export const getRolePhoto = (type) => {
    const icon = roles.find(role => role.type === type);
    return icon ? icon.icon : null;
}

export const getRoleTitle = (type) => {
    const title = roles.find(role => role.type === type)
    return title ? title.title : null;
}