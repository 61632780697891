<template>
    <div class="bg-white border border-gray-200 rounded-2xl shadow-base p-6 flex flex-col h-full">
        <created-at :data="props.data" />
        <div class="mt-7 block sm:flex">
            <div class="flex">
                <icon icon-name="calendar" />
                <span class="ml-3 text-black">{{moment(props?.data?.start_at).format('DD.MM.yy')}}</span>
            </div>
            <div class="flex mt-2 sm:mt-0 sm:ml-10">
                <icon icon-name="mapPin" />
                <span class="ml-2 text-black">{{props?.data?.place_info}}</span>
            </div>
        </div>

        <div class="mt-5 block sm:flex">
            <div class="w-full sm:w-2/5 mr-5" v-if="props.data.banner_url">
                <img :src="props.data.banner_url" class="rounded-2xl" alt="">
            </div>
            <div class="mt-7 sm:mt-0">
                <div class="text-base font-bold mt-3">
                    {{props?.data?.direction?.name}}
                </div>
                <div class="text-lg text-base-color font-bold mt-3">
                    {{props?.data?.title}}
                </div>
            </div>
        </div>

        <div class="block sm:flex sm:space-x-4 mt-7">
            <div class="w-full sm:w-1/3" v-if="props?.data?.is_active || props.pastEvent">
                <button class="btn btn_blue w-full !text-sm p-3 d_center"
                        @click="router.push({name: 'file-upload', params: {id: props?.data?.uuid}})">
                    <icon icon-name="setupWhite" class="mr-3"/>
                    Управление
                </button>
            </div>
            <div class="w-full mt-4 sm:mt-0 sm:w-1/3">
                <button class="btn header_auth btn_white d_center !text-sm p-3 w-full" @click="router.push({name: 'show-competition', params: {id: props?.data?.uuid}})">
                    Посмотреть
                </button>
            </div>
            <div с class="w-full mt-4 sm:mt-0 sm:w-1/3 flex space-x-4 justify-between sm:justify-end"
                 :class="{'sm:w-2/3': !props?.data?.is_active && !props.pastEvent, 'sm:w-1/3': props?.data?.is_active || props.pastEvent}"
                 v-if="store.state.permissions.includes('public_create_event') && store.state?.user?.id === props?.data?.owner_id">
                <div class="flex space-x-4">
                    <div class="h-11 w-11 bg-sky-blue rounded-2xl cursor-pointer d_center" v-if="!pastEvent"
                         @click="router.push({name: 'create-competition', params: {id: props?.data?.uuid}})">
                        <icon icon-name="edit" />
                    </div>
                    <!-- <div class="h-11 w-11 bg-sky-blue rounded-2xl cursor-pointer d_center">
                        <icon icon-name="copy" />
                    </div> -->
                </div>
                <div class="h-11 w-11 bg-danger-light rounded-2xl cursor-pointer d_center" v-if="!pastEvent" @click="eventModal = true;">
                    <icon icon-name="delete" />
                </div>
            </div>
        </div>
        <Modal :visible="eventModal">
            <div class="text-left text-base-color font-bold text-lg">Удалить соревнование</div>
            <div class="text-sm mt-5">Вы действительно хотите удалить соревнование?</div>
            <div class="flex mt-5 space-x-4">
                <button class="btn btn_white w-1/2 !text-sm p-3" @click="closeModal">Не удалять</button>
                <button class="btn btn_red w-1/2 !text-sm p-3" @click="deleteEvent">Удалить</button>
            </div>

        </Modal>
    </div>
</template>

<script setup>
import {defineProps, onMounted, ref} from "vue";
import moment from 'moment';
import {navigateTo, uuid} from "@/utils/helpers";
import ToggleSwitch from "@/views/components/inputComponent/toggleSwitch.vue";
import Icon from "@/views/components/helpers/icon.vue";
import router from "@/router/router";
import Modal from "@/views/components/modal/modal.vue";
import useEmitter from "@/utils/useEmitter";
import CreatedAt from "@/views/components/competitions/createdAt.vue";
import store from "@/store";

const emitter = useEmitter();
const eventModal = ref(false);
const props = defineProps({
    data: {
        type: Object,
        default: {}
    },
    pastEvent: {
        type: Boolean,
        default: false
    }
})
const closeModal = () => {
    eventModal.value = false;
};

const deleteEvent = () => {

}


emitter.on('closeModal', () => {closeModal();})



</script>
