<template>
    <wrap-section>

        <!-- <search-sportsmans-input /> -->

        <input-search v-model="search" @input="searchHandler" />

        <div class="divide-y divide-light-border divide-solid mt-7">
            <!-- <div v-if="store.state.sportsman.data.length === 0"> -->
            <div v-if="false">
                Вы ещё не добавили ни одного спортсмена
            </div>
            <mySportsman v-else v-for="sportsmanItem in data" :sportsmanItem="sportsmanItem" :key="sportsmanItem.uuid"/>
        </div>

        <pagination :meta="meta" v-model="currentPage"/>

    </wrap-section>
</template>

<script setup>
import {apiRequest, getMyStoreSportsman, getSportsman, navigateTo} from "@/utils/helpers";
import {onMounted, ref, watch} from "vue";
import wrapSection from "@/views/components/helpers/wrapSection";
import mySportsman from "./mySportsman.vue";
import store from "@/store";
import icon from "@/views/components/helpers/icon.vue";
import InputSearch from "@/views/components/inputComponent/inputSearch.vue";
import Pagination from "@/views/components/helpers/pagination.vue";
import {SPORTSMAN, SPORTSMAN_SEARCH} from "@/router/api";


const meta = ref({});
const input = ref("");
const data = ref([]);
const currentPage = ref(1);
const search = ref('');

const searchHandler = () => {
  // Сбрасываем текущую страницу на 1 при изменении поиска
  currentPage.value = 1;
  loadTrainersSportsmans();
}


const loadTrainersSportsmans = async (page = 1) => {
    try {
        // Создаем объект параметров
        let params = { page: currentPage.value };
        if (search.value !== '') {
            params.sportsman_search = search.value;
        }

        // Делаем запрос к API с параметрами
        const response = await apiRequest(
            search.value !== '' ? SPORTSMAN_SEARCH : SPORTSMAN, 
            { params },
            'get'
        );

        // Проверяем ответ и обновляем данные
        if (response && response.success && response.result) {
            data.value = response.result.data;
            meta.value = response.result.pagination;
        }
    } catch (e) {
        console.error('Ошибка при загрузке данных о спортсменах:', e);
    }

};


watch(currentPage, (newPage) => {
    loadTrainersSportsmans(newPage);
});

onMounted(async () => {
    // Спортсмены тренера
    data.value = getMyStoreSportsman(5);
    await loadTrainersSportsmans(currentPage.value);
});

defineExpose({
    loadTrainersSportsmans,
});

</script>
