<template>
  <div class="alert" v-if="visible" :class="{success: isSuccess}">
    {{ info }}
  </div>
</template>

<script setup>
import useEmitter from "@/utils/useEmitter";
import {defineProps, ref, defineExpose} from "vue";

const props = defineProps({
    alertType: String,
    default: false
});

let visible   = ref(false);
let isSuccess = ref(false);
let info = ref('');
let alert_timeout = 12000;

const emitter = useEmitter();

function alertOff(){visible.value = false; isSuccess.value = false }

// принудительный сброс
function clear() {
    alertOff();
}

emitter.on('alert', value => {
    if(value.alertType && value.alertType === props.alertType) {
        info.value = value.message;
        visible.value = true;
    } else if(!value.alertType) {
        info.value = value;
        visible.value = true;
    }
    setTimeout(alertOff, alert_timeout);
})
emitter.on('success', value => {
    if(value.alertType && value.alertType === props.alertType) {
        info.value = value.message;
        visible.value = true;
        isSuccess.value = true;
    }
    setTimeout(alertOff, alert_timeout);
})

defineExpose({ clear });
</script>

<style scoped>
.alert {
  font-size: 14px;
  background-color: var(--danger-light);
  margin-top: 20px;
  color: var(--danger-dark);
  padding: 15px;
  border-radius: 15px;
  text-align: center;
}
.success {
  font-size: 14px;
  background-color: var(--success-light);
  margin-top: 20px;
  color: var(--black);
  padding: 15px;
  border-radius: 15px;
  text-align: center;
}
</style>
