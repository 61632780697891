<template>
    <div class="w-full">
<!--        <input-custom label="Название поля" type="text" v-model="fd.title" class="mt-4 mob_margin"-->
<!--                      labelClass="pass-update-org"/>-->
        <div class="wrap_input mt-5" v-bind="$attrs">
            <div class="label-personal-page font-bold text-sm flex justify-normal items-center">Категория</div>
            <VueMultiselect
                v-model="fd.direction_category"
                :options="priceItemCategory"
                track-by="id"
                label="title"
                deselect-label="Удалить"
                select-label="Выбрать"
                selected-label="Выбрано"
                :multiple="false"
                placeholder="Не выбрана"
            >
                <template v-slot:noResult class="no-result">
                    <span>Карегория не выбрана</span>
                </template>
            </VueMultiselect>

        </div>
        <input-rub label="Цена*" v-model="fd.price" class="mt-4 mob_margin" labelClass="pass-update"/>
    </div>
</template>



<script setup>
import InputCustom from "@/views/components/inputComponent/inputCustom.vue";
import {defineProps, onMounted, ref, watch} from "vue";
import useEmitter from "@/utils/useEmitter";
import InputRub from "@/views/components/inputComponent/inputRub.vue";
import VueMultiselect from "vue-multiselect";
import store from "@/store";
import {getDirectionCategories, getSportsman} from "@/utils/helpers";

const emitter = useEmitter();

const props = defineProps({
    data: {
        type: Object,
        default: { price: 0, title: '', direction_category: [] },
    },
    index: {
        type: Number,
        default: 0,
    },
    priceItemCategory: {
        type: Array,
        default: [],
    }
});

const fd = ref({
    direction_category: [],
    info: 'debug',
    price: "",
    title: '',
});

watch(fd, () => {
    if(fd.value.price !== props.data.price || fd.value.title !== props.data.title || fd.value.direction_category !== props.data.direction_category) {
        emitter.emit('competitionPrices', [props.index, fd.value])
    }
}, { deep: true });

watch(() => props.data, (newValue) => {
    if (fd.value.price !== newValue.price || fd.value.title !== newValue.title || fd.value.direction_category !== props.data.direction_category ) {
        fd.value.price = newValue.price.toString();
        fd.value.title = newValue.title;
        fd.value.direction_category = newValue.direction_category;
    }
}, { deep: true, immediate: true });

</script>