<template>
    <wrap-section class="mt-5">
        <div class="flex justify-between">
            <section-header class="text-dark-base" header="Авторизация по email пользователя"/>
        </div>

        <input-custom label="Email*" type="text" v-model="fd.email" class="mt-4 mob_margin"
                      labelClass="pass-update-org"/>

        <alerts alertType="loginAsUser"/>

        <div class="w-full flex justify-end">
            <button class="btn mt-8 btn_blue w-full sm:w-64 d_center" @click="loginAsUser">
                <span v-if="isLoading" class="loader inline-block mr-3"></span>
                <span class="text-white">Авторизоваться</span>
            </button>



        </div>
    </wrap-section>
</template>

<script setup>
import SectionHeader from "@/views/components/dashboard/sectionHeader.vue";
import {ref} from "vue";
import {apiRequest} from "@/utils/helpers";
import {EVENTS, LOGIN_AS_USER} from "@/router/api";
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import InputCustom from "@/views/components/inputComponent/inputCustom.vue";
import Alerts from "@/views/components/helpers/alerts.vue";
import useEmitter from "@/utils/useEmitter";

const emitter = useEmitter();
const isLoading = ref(false);

const fd = ref({
    email: ''
});

const validation = () => {
    let base = 'Проверьте корректность ввода поля '
    if (fd.value.email.length < 4) return emitter.emit("alert", {alertType: 'loginAsUser', message: base + 'email'});
    if (!fd.value.email.includes('@')) return emitter.emit("alert", {
        alertType: 'loginAsUser',
        message: base + 'email'
    });
    if (!fd.value.email.includes('.')) return emitter.emit("alert", {
        alertType: 'loginAsUser',
        message: base + 'email'
    });

    return true;
}

const loginAsUser = () => {
    if (validation() !== true)
        return;
    isLoading.value = true;
    apiRequest(LOGIN_AS_USER, fd.value, 'post')
        .catch(e => {
            console.log('error', e)
            if(e.message) {
                emitter.emit("alert", {
                    alertType: 'loginAsUser',
                    message: e.message
                });
            }
        })
        .then(resp => {
            if (resp && resp.success) {
                window.location.reload();
            }
        })
        .finally(() => {
            isLoading.value = false;
        });
}

</script>

